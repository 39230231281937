import * as React from 'react'
import { Link } from 'gatsby'
import truncate from 'lodash/truncate'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
interface WorkCardProps {

    slug: string;
    image: IGatsbyImageData;
    title: string;
    description: string;
    categories: string[];
    stats: IStats[]
}
interface IStats {
    number: string;
    description: string;
}

export default function WorkCard({ slug, image, title, description, categories, stats }: WorkCardProps) {
    return (
        <div className="w-full sm:w-1/2 lg:w-1/3 px-3 mb-6"


        >
            <article className="resource h-full">
                <Link to={slug ? slug : `/resources/${slug}`} className="resource__link relative flex flex-col h-full">
                    <div className="resource__fimage ">
                        <div className="relative overflow-hidden pt-66perc image image--center">
                            <GatsbyImage image={image} alt={`random`} />
                        </div>

                    </div>
                    <div className="resource__content p-resp-5-8 bg-theme-dark flex-1 relative z-10">
                        <h4 className="resource__heading text-md leading-tight mb-2 lg:mb-3">
                            {title}
                        </h4>
                        <div className="resource__excerpt text-sm text-white text-opacity-60 mb-5 lg:mb-7">
                            {truncate(description, { length: 190 })}
                        </div>
                        <div className="resource__footer flex justify-between items-center">

                            <ul className="resource__categories flex items-center -mx-2">{(categories).map((cat, i) => {
                                return (
                                    <li key={i} className="px-2 text-white text-opacity-60 text-3xs font-bold capitalize">{cat}</li>
                                )
                            })}</ul>
                        </div>
                    </div>
                    <div className="resource__learnmore lg:absolute bottom-0 left-0 w-full px-resp-5-8 bg-theme-dark lg:h-20 flex items-center z-0 pb-2 lg:pb-0">

                        {stats && (
                            <li className="block--stat block--stat--sm">
                                <div className="stat__num">
                                    <span className="text">{stats[0].number}
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 150 83.87"
                                        >
                                            <defs></defs>
                                            <g id="Layer_2" data-name="Layer 2">
                                                <g id="Layer_1-2" data-name="Layer 1">
                                                    <path
                                                        className="cls-1"
                                                        d="M149.67,5.71l.23-1h0l0-.17h0a3.9,3.9,0,0,0-.51-2.73A3.81,3.81,0,0,0,145.59.05h0L109.75,6.18l1.58,9.24,19.32-3.3L93.19,49.58a11.63,11.63,0,0,0-8.53,0L67.06,32a11.7,11.7,0,1,0-21.78,0L16,61.28a11.69,11.69,0,1,0,6.61,6.6l29.3-29.29a11.73,11.73,0,0,0,8.53,0L78,56.19a11.7,11.7,0,1,0,21.78,0l36.85-36.86-4.48,18.35,9.14,2.22,8.15-33.38"
                                                    />
                                                </g>
                                            </g>
                                        </svg>
                                    </span>

                                </div>
                                <p className="stat__text">{stats[0].description}</p>
                            </li>
                        )}
                    </div>
                </Link>
            </article>
        </div>
    )
}