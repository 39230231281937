import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import { difference } from 'lodash'
import PageHeader from '../../components/sections/PageHeader'
import { getImage } from "gatsby-plugin-image"
import CallToAction from '../../components/sections/CallToAction'
import WorkCard from '../../components/WorkCard'

export default function WorkPage({ data: { works } }) {

    const workReduce = ['all', ...works.categories]
    const [displayedCategory, setDisplayedCategory] = useState(['all'])

    return (
        <Layout >
            <Seo
                title="Our Work | SEO, SEM, PPC Services & More | Majiska"
                description="Our work says everything about our digital marketing expertise. So do our clients. The case studies below demonstrate the calibre of results the Majiska team have achieved for businesses just like yours." />
            <PageHeader
                heading={`Our Work`}
                content={`Our work says everything about our digital marketing expertise. So do our clients. The case studies below demonstrate the calibre of results the Majiska team have achieved for businesses just like yours.`}
            />
            <div className="container--padded pb-resp-16-32">
                <div className="pb-10 lg:pb-16">
                    <div className="flex flex-wrap justify-center">

                        {workReduce.map(cat => {
                            return (
                                <div className={`mx-4 px-4 mb-2 cursor-pointer rounded-md capitalize ${displayedCategory == cat ? 'border border-accent' : ''}`} onClick={() => {
                                    if (displayedCategory != cat) {
                                        setDisplayedCategory([cat])

                                    }
                                }} key={cat}>
                                    {cat}
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="flex  flex-wrap -mx-3">
                    {works.edges.filter(({ node: work }) => {

                        if (displayedCategory == 'all') {
                            return true
                            // temporarily remove featured until we have enough case studies
                            // } else if (displayedCategory == 'featured') {
                            //     return work.frontmatter.isFeatured === true
                        } else {
                            return difference(displayedCategory, work.categories).length === 0

                        }

                    })
                        .map(({ node: work }) => {
                            const image = getImage(work.bannerImage)

                            return (
                                <WorkCard
                                    key={work.id}
                                    slug={work.slug}
                                    image={image}
                                    title={work.title}
                                    description={work.description}
                                    categories={work.categories}
                                    stats={work.stats}
                                />

                            )
                        })}
                </div>
            </div>
            <CallToAction />
        </Layout>
    )
}

export const pageQuery = graphql`
  query {
    works:allContentfulPastWorks {
        categories: distinct(field: categories)
        edges {
          node {
            id
            title
            slug
            date: createdAt(formatString: "MMMM DD, YYYY")
            description
            categories
            markAsFeatured
            stats {
              number
              description
            }
            bannerImage {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
  }
`
